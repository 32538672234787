import styles from './Contact.module.css';

const Contact = (props) => {
  return (
    <div className={styles.contact}>
      <h1 className={styles.heading}>
        {props.engLang ? 'Contact' : 'İletişim'}
      </h1>
      <h2 className={styles.emailText}>burak.altintas@yahoo.com.tr</h2>
      <div className={styles.icons}>
        <a
          className={styles.iconTwitter}
          href='https://twitter.com/burak_alti'
          target='_blank'
          rel='noreferrer'
        >
          <svg
            className={styles.icon}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 512 512'
          >
            <title>Logo Twitter</title>
            <path d='M496 109.5a201.8 201.8 0 01-56.55 15.3 97.51 97.51 0 0043.33-53.6 197.74 197.74 0 01-62.56 23.5A99.14 99.14 0 00348.31 64c-54.42 0-98.46 43.4-98.46 96.9a93.21 93.21 0 002.54 22.1 280.7 280.7 0 01-203-101.3A95.69 95.69 0 0036 130.4c0 33.6 17.53 63.3 44 80.7A97.5 97.5 0 0135.22 199v1.2c0 47 34 86.1 79 95a100.76 100.76 0 01-25.94 3.4 94.38 94.38 0 01-18.51-1.8c12.51 38.5 48.92 66.5 92.05 67.3A199.59 199.59 0 0139.5 405.6a203 203 0 01-23.5-1.4A278.68 278.68 0 00166.74 448c181.36 0 280.44-147.7 280.44-275.8 0-4.2-.11-8.4-.31-12.5A198.48 198.48 0 00496 109.5z' />
          </svg>
        </a>
        <a
          className={styles.iconLinkedin}
          href='https://www.linkedin.com/in/buraltintas/'
          target='_blank'
          rel='noreferrer'
        >
          <svg
            className={styles.icon}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 512 512'
          >
            <title>Logo Linkedin</title>
            <path d='M444.17 32H70.28C49.85 32 32 46.7 32 66.89v374.72C32 461.91 49.85 480 70.28 480h373.78c20.54 0 35.94-18.21 35.94-38.39V66.89C480.12 46.7 464.6 32 444.17 32zm-273.3 373.43h-64.18V205.88h64.18zM141 175.54h-.46c-20.54 0-33.84-15.29-33.84-34.43 0-19.49 13.65-34.42 34.65-34.42s33.85 14.82 34.31 34.42c-.01 19.14-13.31 34.43-34.66 34.43zm264.43 229.89h-64.18V296.32c0-26.14-9.34-44-32.56-44-17.74 0-28.24 12-32.91 23.69-1.75 4.2-2.22 9.92-2.22 15.76v113.66h-64.18V205.88h64.18v27.77c9.34-13.3 23.93-32.44 57.88-32.44 42.13 0 74 27.77 74 87.64z' />
          </svg>
        </a>
        <a
          className={styles.icon}
          href='mailto:burak.altintas@yahoo.com.tr'
          target='_blank'
          rel='noreferrer'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className={styles.iconMail}
            viewBox='0 0 512 512'
          >
            <title>Mail</title>
            <rect
              x='48'
              y='96'
              width='416'
              height='320'
              rx='40'
              ry='40'
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='32'
            />
            <path
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='32'
              d='M112 160l144 112 144-112'
            />
          </svg>
        </a>
        <a
          className={styles.iconGithub}
          href='https://github.com/buraltintas'
          target='_blank'
          rel='noreferrer'
        >
          <svg
            className={styles.icon}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 512 512'
          >
            <title>Logo Github</title>
            <path d='M256 32C132.3 32 32 134.9 32 261.7c0 101.5 64.2 187.5 153.2 217.9a17.56 17.56 0 003.8.4c8.3 0 11.5-6.1 11.5-11.4 0-5.5-.2-19.9-.3-39.1a102.4 102.4 0 01-22.6 2.7c-43.1 0-52.9-33.5-52.9-33.5-10.2-26.5-24.9-33.6-24.9-33.6-19.5-13.7-.1-14.1 1.4-14.1h.1c22.5 2 34.3 23.8 34.3 23.8 11.2 19.6 26.2 25.1 39.6 25.1a63 63 0 0025.6-6c2-14.8 7.8-24.9 14.2-30.7-49.7-5.8-102-25.5-102-113.5 0-25.1 8.7-45.6 23-61.6-2.3-5.8-10-29.2 2.2-60.8a18.64 18.64 0 015-.5c8.1 0 26.4 3.1 56.6 24.1a208.21 208.21 0 01112.2 0c30.2-21 48.5-24.1 56.6-24.1a18.64 18.64 0 015 .5c12.2 31.6 4.5 55 2.2 60.8 14.3 16.1 23 36.6 23 61.6 0 88.2-52.4 107.6-102.3 113.3 8 7.1 15.2 21.1 15.2 42.5 0 30.7-.3 55.5-.3 63 0 5.4 3.1 11.5 11.4 11.5a19.35 19.35 0 004-.4C415.9 449.2 480 363.1 480 261.7 480 134.9 379.7 32 256 32z' />
          </svg>
        </a>
        <a
          className={styles.iconGithub}
          href='https://medium.com/@burak.altintas'
          target='_blank'
          rel='noreferrer'
        >
          <svg viewBox='0 0 1043.63 592.71' class='de bk'>
            <g data-name='Layer 2'>
              <g data-name='Layer 1'>
                <path d='M588.67 296.36c0 163.67-131.78 296.35-294.33 296.35S0 460 0 296.36 131.78 0 294.34 0s294.33 132.69 294.33 296.36M911.56 296.36c0 154.06-65.89 279-147.17 279s-147.17-124.94-147.17-279 65.88-279 147.16-279 147.17 124.9 147.17 279M1043.63 296.36c0 138-23.17 249.94-51.76 249.94s-51.75-111.91-51.75-249.94 23.17-249.94 51.75-249.94 51.76 111.9 51.76 249.94'></path>
              </g>
            </g>
          </svg>
        </a>
      </div>
    </div>
  );
};

export default Contact;
